.body {
    height: auto;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 20px;
    padding: 2rem;
    background-color: #0d6efd;
    margin-top: 80px;
    padding: 20px;
  }


  .body h1, h2, h3, h4, h5, h6, b, dl, dt, ol, p, a, label {
    padding: 1rem;
    color: white;
  }
  
 .article {
  padding: 1rem;
  color: white;
 }



