.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 15px 1rem 15px;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
}

.container input{
    margin: 0;
    height: 50px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    padding-left: 15px;
    border: none;
    background: white;
    border-radius: 30px;
    color: #0d6efd;
    border: none;
    border-radius: 5px;
    font-size: 16px;
 }

 .container input:focus
 {
  text-decoration: none;
  transition: 100ms ease-in;
  border-color:  rgb(39, 143, 255);
  outline: none;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(39, 143, 255, 0.6);
}


.container button{
    margin-top: 2rem;
    margin: 0;
    width: 100%;
     padding: 10px 20px;
     border: none;
     background: #0d6efd;
     border-radius: 30px;
     color: white;
     border: none;
     border-radius: 5px;
    font-size: 16px;
     margin-top: 1rem; 
     margin-bottom: 1rem;
 }