* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: 'Encode Sans Expanded', sans-serif;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

