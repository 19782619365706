.navbar {
    font-family: 'PT Sans', sans-serif;
    background: black;
    height: 60px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: fixed;
    top: 0;
  
    }



    .navbar-logo {
        justify-self: start;
        padding: 1rem;
        margin-left: 20px;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.5rem;
    }
    
    
    
    .nav-menu {
        display: grid;
        grid-template-columns: repeat(4, auto);
        grid-gap: 20px;
        list-style: none;
        text-align: center;
        width: 100vw;
        justify-content: end;
        margin-right: 2rem;
    
    }
     
    .nav-item {
        display: flex;
        align-items: center;
        height: 60px;    
    }
    
    
    .nav-links{
        color: white;
        text-decoration: none;
        padding: 0.5rem 1rem;
    }
    
    .nav-links:hover {
        border-radius: 35px;
        border: none;
        transition: 250ms ease;
    }
    
   

    .fa-bars {
        color: grey;
    }
    
    .nav-links-mobile {
        display: none;
    }
    
    .menu-icon {
        display: none;
    }
    
    @media screen and (max-width: 960px) {
        
    
        .nav-menu {
            position: sticky;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 110vh;
            position: absolute;
            top: 59px;
            left: -100%;
            opacity: 1;
            z-index: 99;
            transition: all 0.5s ease;
            
        }
    
        .nav-menu.active{
            position: fixed;

            background: black;
            left: 0;
            opacity: 1;
            z-index: 99;
            transition: all 0.5s ease-out;
        }

      
    
         .nav-links-mobile {
            text-align: center;
            border-radius: none;
            width: 100vw;
            display: table;
            table-layout: inherit;
        }
    
        
        .navbar-logo {
            position: sticky;
            margin-left: inherit;
        }
        
        .menu-icon {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(-90%,10%);
            font-size: 1.5rem;
            cursor: pointer; 
        }
        
    
        
    }