.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    justify-content: center;
    height: auto;
    margin-top: 50px;
    width: 100vw;
    background-color: #0d6efd;
}